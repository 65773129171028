/* Authentication Page Styles */
.auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f9;
  }
  
  .auth-container h2 {
    font-size: 2.5rem;
    color: #004eff;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .auth-container form {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .auth-container input {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    outline: none;
  }
  
  .auth-container input:focus {
    border-color: #004eff;
  }
  
  .auth-container button {
    width: 100%;
    padding: 15px;
    background-color: #004eff;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-container button:hover {
    background-color: #003fcc;
  }
  
  .auth-container .error {
    color: #ff4b4b;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .auth-container p {
    text-align: center;
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
  }
  
  .auth-container p a {
    color: #004eff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .auth-container p a:hover {
    color: #003fcc;
  }
  
  /* Responsive Styles */
  @media (max-width: 500px) {
    .auth-container form {
      padding: 20px;
    }
  
    .auth-container h2 {
      font-size: 2rem;
    }
  
    .auth-container input, .auth-container button {
      font-size: 0.9rem;
      padding: 12px;
    }
  }
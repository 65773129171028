/* General Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #f4f4f9;
  color: #333;
}

.app {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 30px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: auto;
}

.logo {
  font-size: 1.3rem;
  font-weight: bold;
  color: #004eff;
}

.search-container {
  position: relative;
  width: 40%;
}

.search-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #fff;
  outline: none;
  font-size: 0.9rem;
}

/* Dropdown */
.dropdown {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-item img {
  width: 50px;
  height: 75px;
  object-fit: cover;
  margin-right: 10px;
}

.dropdown-item h4 {
  font-size: 1rem;
  margin: 0;
}

.dropdown-item p {
  font-size: 0.8rem;
  color: #888;
}

/* Home Link */
.home-link {
  font-size: 1.1rem;
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
}

.home-link:hover {
  color: #e50914;
}

/* Auth Button Styles */
.auth-button {
  padding: 10px 20px;
  background-color: #004eff;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-left: 10px;
  border: none;
  cursor: pointer;
}

.auth-button:hover {
  background-color: #003fcc;
}

.auth-button:focus {
  outline: none;
}

/* Main Sections for Pre-Selected Movies */
.sections-container {
  width: 100%;
  padding-top: 80px; /* Adjust for navbar height */
  padding-left: 30px;
  padding-right: 30px;
}

.section {
  margin-bottom: 40px;
}

.section h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

.movies-row {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.movie-card {
  min-width: 150px;
  text-align: center;
  cursor: pointer;
}

.movie-card img {
  width: 100%;
  height: 225px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.movie-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.movie-card h4 {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}

hr {
  border: none;
  border-top: 2px solid #ddd;
  margin: 20px 0;
}

/* Player and Movie Details Page styles */
.player-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 80px; /* Adjust for the navbar height */
}

.content-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.movie-player {
  flex: 3;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.movie-title {
  font-size: 2rem;
  color: #333;
  margin: 20px 0 10px;
}

.movie-description {
  font-size: 0.9rem;
  color: #555;
  padding: 10px;
  border-top: 1px solid #ddd;
}

/* Recommended Movies section */
.recommended-section {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.recommended-section h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.recommended-movies {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.recommended-card {
  display: flex;
  gap: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  transition: transform 0.3s ease;
  cursor: pointer;
  border: 1px solid #ddd;
}

.recommended-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.recommended-card img {
  width: 50px;
  height: 75px;
  object-fit: cover;
}

.recommended-info h4 {
  font-size: 1rem;
  color: #333;
}

.recommended-info p {
  font-size: 0.8rem;
  color: #777;
}

/* Login Prompt */
.login-prompt {
  text-align: center;
  padding: 50px;
}

.login-prompt h2 {
  font-size: 1.8rem;
  color: #333;
}

.login-prompt .auth-button {
  margin-top: 20px;
  padding: 12px 25px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 10px;
    height: auto;
  }

  .search-container {
    width: 90%;
  }

  .auth-button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  .sections-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 50px;
  }

  .movie-card {
    min-width: 120px;
  }

  .movie-player {
    padding: 15px;
  }

  .recommended-section {
    padding: 15px;
  }

  .recommended-card img {
    width: 40px;
    height: 60px;
  }

  .content-section {
    flex-direction: column;

  }

  .movie-title {
    font-size: 1.5rem;
  }

  .movie-description {
    font-size: 0.8rem;
  }

  .recommended-section h3 {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 5px;
    height: auto;
  }

  .logo {
    font-size: 1rem;
  }

  .auth-button {
    padding: 5px 10px;
    margin-top: 10px;
    font-size: 0.8rem;
  }

  .movie-card {
    min-width: 100px;
  }

  .movie-player {
    padding: 10px;
  }

  .movie-title {
    font-size: 1.3rem;
  }

  .movie-description {
    font-size: 0.7rem;
  }
}
